<template>
  <div class="beijing">
    <h1>现代农事服务中心数字化管理平台</h1>

    <div class="zhong">
      <div class="ckimg"></div>
      <div class="form">
        <form @submit.prevent="login">
          <label>
            <img src="../../assets/login/username.png" alt="" />
            <input
              type="text"
              placeholder="请输入您的用户名"
              v-model="username"
              id="phone"
              maxlength="11"
            />
          </label>
          <br />
          <label>
            <img src="../../assets/login/password.png" alt="" />
            <input
              type="password"
              placeholder="请输入您的密码"
              v-model="password"
            />
          </label>
          <br />
          <label>
            <img src="../../assets/login/yzm.png" alt="" />
            <input type="text" placeholder="请输入验证码" v-model="captcha" />
            <captcha @getCaptcha="getCaptcha" />
          </label>

          <button type="submit">安全登录</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import captcha from './captcha.vue';
export default {
  components: { captcha },
  data () {
    return {
      username: '',
      password: '',
      captcha: '',
      captchas: ''
    };
  },
  methods: {
    getCaptcha (e) {
      this.captchas = e;
    },
    //表单校验
    verify () {
      //表单校验
      if (this.username.trim() == '') {
        this.$message.error('请输入用户名');
        return false;
      }
      if (this.password.trim() == '') {
        this.$message.error('请输入密码');
        return false;
      }

      if (this.captcha.trim() == '') {
        this.$message.error('请输入验证码');
        return false;
      }
      return true;
    },
    login () {
      if (!this.verify()) return;
      if (
        this.captcha.trim().toLowerCase() !== this.captchas.trim().toLowerCase()
      ) {
        this.$message.error('请输入正确验证码');
        return;
      }
      // 比较账户和密码是否正确
      this.$store
        .dispatch('Login', {
          username: this.username,
          password: this.password
        })
        .then(res => {
          if (res.data.level === 0) {
            this.$message.success(res.msg);
            this.$router.push('/');

            if (res.code === '1') {
              const mapPoint = {
                longitude: res.data.longitude,
                latitude: res.data.latitude,
                name: res.data.tenantName
              };
              localStorage.setItem('key', JSON.stringify(mapPoint));
            }
          } else {
            this.$message.success(res.msg);
            this.$router.push('/overview');
          }
        })
        .catch(err => {
          this.$message.error(err.msg);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.beijing {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/login/background.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;

  .zhong {
    width: companyW(520vw);
    height: companyH(570vh);

    .ckimg {
      position: absolute;
      top: companyH(230vh);
      left: companyW(350vw);
      width: companyW(900vw);
      height: companyH(650vh);
      background-image: url('../../assets/login/ckimg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .form {
      width: companyW(450vw);
      height: companyH(350vh);
      padding-top: companyH(150vh);
      position: absolute;
      background-image: url('../../assets/login/kcbj.png');
      top: companyH(300vh);
      right: companyW(150vw);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}

.beijing > h1 {
  margin: 0 auto;
  font-family: 'Adobe Heiti Std R';
  letter-spacing: companyW(0vw);
  font-size: companyH(42vh);
  color: #6de7ff;
  padding-top: companyW(10vw);
}

.zhong > .form > form > label {
  width: companyW(380vw);
  height: companyH(58vh);
  margin: 0 auto companyH(5vh);
  display: flex;
}

.zhong > .form > form > label:last-of-type {
  margin-bottom: companyH(20vh);
}

.zhong > .form > form > label > img {
  display: inline-block;
}

.zhong > .form > form > label > input {
  display: inline-block;
  opacity: 0.5;
  width: companyW(322vw);
  height: companyH(58vh);
  background: rgba(18, 211, 252, 0.04);
  border: 1px solid #134b7e;
  color: #ffffff;
  font-size: companyH(20vh);
}

.zhong > .form > form > label > input::placeholder {
  width: companyW(155vw);
  height: companyH(50vh);
  font-size: companyH(20vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #a4d6ef;
  line-height: 132px;
}

.zhong > .form > form > button {
  width: companyW(381vw);
  height: companyH(51vh);
  background: #006cc6;
  border: 1px solid #134b7e;
  color: #ffffff;
}
</style>
